.page_wrapper {
  display: grid;
  grid-gap: 2.4rem;
  padding: 0 1.6rem 1.6rem 1.6rem;
  overflow-y: auto;
  overflow-x: hidden;
  grid-row: listTop / listBottom;

  > div {
    display: grid;
    grid-gap: 1.2rem;
  }
}
