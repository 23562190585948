@import "src/app/styles/assets/scss/colors";

.wrapper {
  position: relative;
  height: 100%;
  width: 100%;
  padding-top: env(safe-area-inset-top);

  .on_load_image {
    position: absolute;
    top: 0;
    left: 0;
    height: 24.4rem;
    width: 100%;
    background-color: $grayLighter;
  }

  .preview_background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 16.5rem;
    z-index: 1;
    background: linear-gradient(0deg, rgba(43, 43, 43, 0.00) 0%, rgba(43, 43, 43, 0.24) 50%, rgba(43, 43, 43, 0.60) 100%);
    pointer-events: none;
  }

  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 16.5rem;
    object-fit: cover;
    z-index: 0;
  }

  .content {
    width: 100%;
    box-sizing: border-box;
    position: absolute;
    top: 14.1rem;
    height: calc(100% - 14.1rem);
    background-color: $grayLightrest1;
    border-radius: 1.6rem 1.6rem 0 0;
    z-index: 1;
    overflow: hidden;

    .list {
      box-sizing: border-box;
      padding: 0 1.6rem;
      margin-top: 2.4rem;
      overflow-y: auto;
      height: calc(100% - 2.4rem);

      .skeleton_item {
        display: grid;
        grid-template-columns: 1fr 2rem;
        align-items: center;
        grid-gap: 1.2rem;
        margin-bottom: 2.4rem;
      }
    }
  }
}
